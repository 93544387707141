import React, { useContext } from 'react';
import { CartContext } from '../context/CartContext';
import { Link } from 'react-router-dom';

const ProductCard = ({ product }) => {
    const { addItem } = useContext(CartContext);

    return (
        <div className="product-card">
            <Link to={`/product/${product.id}`}>
                <img src={product.image} alt={product.name} />
                <h3>{product.name}</h3>
            </Link>
            <p>R{product.price}</p>
            <button onClick={() => addItem(product)}>Add to Cart</button>
        </div>
    );
};

export default ProductCard;
