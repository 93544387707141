import React from 'react';
import { useParams } from 'react-router-dom';
import products from '../data/products.json';

const ProductDetail = () => {
    const { id } = useParams();
    const product = products.find(p => p.id === parseInt(id));

    if (!product) {
        return <h2>Product not found</h2>;
    }

    return (
        <div className="product-detail">
            <img src={product.image} alt={product.name} />
            <h1>{product.name}</h1>
            <p>R{product.price}</p>
            <p>{product.description}</p>
            <p>{product.description2}</p>
        </div>
    );
};

export default ProductDetail;
