// src/components/Header.js
import React from 'react';

const Header = () => (
    <div className="main-content">
    <div className="header-content">
      <h1>Welcome to Microchip Central</h1>
      <p>Your one-stop shop for all things microchips</p>
      <p>Coming soon....</p>
    </div>
  </div>
    
);

export default Header;