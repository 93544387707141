import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Cart from './components/Cart';
import ProductList from './components/ProductList';
import ProductDetail from './components/ProductDetail';
import products from './data/products.json'; // Import products data
import Headers from './components/Header'



const App = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const videoRef = useRef(null);
  const lastScrollY = useRef(0);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.5; // Set the playback rate to slow down the video
    }
  }, []);
  

  const handleHamburgerClick = () => {
    setNavOpen(!navOpen);
  };

  const handleNavItemclick = () => {
    setNavOpen(false);
  };

  const controlHeader = () => {
    if (window.scrollY > lastScrollY.current) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
    lastScrollY.current = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', controlHeader);
    return () => {
      window.removeEventListener('scroll', controlHeader);
    };
  }, []);

  return (
    <Router>
      
      <header className={`header ${showHeader ? '' : 'hidden'}`}>
          <div className="top-bar">
            <div className="top-bar__content">
              <section className="phone">
                <i className="fa-solid fa-phone icon"></i>
                <span>012-456-0334</span>
              </section>
              <section className="email">
                <i className="fa-solid fa-envelope icon"></i>
                <span>info@microchipcentral.com</span>
              </section>
            </div>
          </div>

          <div className="bottom-bar">
            <div className="bottom-bar__content">
              <a href="/" className="logo">
                <img className="logo__img" src="https://res.cloudinary.com/creative360/image/upload/v1717520940/MicroChip-Central/qtxatxgyz1spls1sfspq.png" alt="logo" />
                <span className="logo__text">Microchip Central</span>
              </a>
              <nav className={`nav ${navOpen ? 'nav--open' : ''}`}>
                <ul className="nav__list">
                  <li className="nav__item" onClick={handleNavItemclick}>
                    <a className="nav__link" href="/">Home</a>
                  </li>
                  <li className="nav__item" onClick={handleNavItemclick}>
                    <a className="nav__link" href="/login">Login</a>
                  </li>
                  <li className="nav__item" onClick={handleNavItemclick}>
                    <a className="nav__link" href="/register">Register</a>
                  </li>
                  <li className="nav__item" onClick={handleNavItemclick}>
                    <a className="nav__link" href="/orders">Orders</a>
                  </li>
                  <li className="nav__item" onClick={handleNavItemclick}>
                    <a className="nav__link" href="/cart">Cart</a>
                  </li>
                  <li className="nav__item" onClick={handleNavItemclick}>
                    <a className="nav__link" href="/account">My Account</a>
                  </li>
                  <li className="nav__item" onClick={handleNavItemclick}>
                    <a className="btn" href="/contact">Contact</a>
                  </li>
                </ul>
              </nav>

              <div
                className={`hamburger ${navOpen ? 'hamburger--open' : ''}`}
                onClick={handleHamburgerClick}
              >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </div>
            </div>
          </div>
        </header>

        <div className="background-video">
          <video
            ref={videoRef}
            className="background-video__content"
            autoPlay
            loop
            muted
            poster="/fallback.jpg"
          >
            <source
              src="https://res.cloudinary.com/creative360/video/upload/f_auto:video,q_auto/v1/MicroChip-Central/xflaqtwox6a7dcyr65jg"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>

        
       
        <div className="app-container">
        <Headers />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<ProductList products={products} />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/product/:id" element={<ProductDetail />} />
            <Route path="/login" element={<div>Login Page</div>} />
            <Route path="/register" element={<div>Register Page</div>} />
            <Route path="/orders" element={<div>Orders Page</div>} />
            <Route path="/account" element={<div>Account Page</div>} />
            <Route path="/contact" element={<div>Contact Us Page</div>} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
    
  );
};

export default App;